import React from "react"

export default function BookaDemo() {
  return (
    <section id="contact">
      <div className="container-fluid">
        <div className="section-header">
          <h3>Book A Consult</h3>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="map mb-4 mb-lg-0">
            <iframe title="Book" frameborder="0" style={{height:"680px", width:"99%", border: "0px",}} src='https://forms.zohopublic.com/xccelerata1/form/XCCELERATAConsultation1/formperma/kjeQqWPIUEYLAGTnW5G_f7lGZi3xZf68BMB2kkgmHYQ'></iframe>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-4 info">
                <i className="bi bi-geo-alt" style={{color:"red"}}></i>
                <p>
                Suite 514, 218 Export Blvd, <br />
                  Mississauga, ON L5S 0A7, Canada
                </p>
              </div>
              <div className="col-md-4 info">
                <i className="bi bi-envelope" style={{color:"red"}}></i>
                <p style={{marginLeft: "5px",}}>contact@xccelerata.com</p>
              </div>
              <div className="col-md-4 info">
                <i className="bi bi-phone" style={{color:"red"}}></i>
                <p>+1-866-224-9493</p>
              </div>
            </div>
            <div className="row">
            <div className="col-md-4 info">
              </div>
              
              
              <img className="book-demo" src="/assets/img/Book_a_consult.png" className="img-fluid" alt=""/>
              <div className="col-md-4 info">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
