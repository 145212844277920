import React from "react"
import "../styles/logo.css"
import "../components/Layout"
import Navigation from "../components/Navigation"
import Includes from "../components/Includes"
import Footer from "../components/Footer"
import Header from "../components/Header"
import BookaDemo from "../components/Book a Demo"


export default function bookademo() {
  return (<>
            <Header/>
            <Navigation/>
            <br></br>
            <BookaDemo/>
            <Includes/>
            <Footer/>

        
  </>)
}